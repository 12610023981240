import React, { useEffect, useState } from "react";
import BorderButton from "../../components/buttons/borderButton";
import FillButton from "../../components/buttons/fillButton";
import moment from "moment";
import {
  getCategories,
  recommendedJobsAfterApply,
} from "../../services/applyOpportunities";
import { useNavigate } from "react-router";
import { JobDescription } from "../jobs/jobDescription/utils/jobDescription";
import CommonBar from "./components/CommonBar";
import { Mixpanel } from "../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";


export const SuccessApplicationSent = () => {
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);
  const [jobId, setJobId] = useState("");
  const navigate = useNavigate();
  const auth =  localStorage.getItem('auth');
  useEffect(() => {
    const fetchRecommended = async () => {
      try {
        const recommendedResponse = await recommendedJobsAfterApply();
        if (
          recommendedResponse &&
          recommendedResponse.recommended_jobs.length > 0
        ) {
          const limitedRecommendedJobs =
            recommendedResponse.recommended_jobs.slice(0, 3);
          setRecommendedJobs(limitedRecommendedJobs);

          const categoriesResponse = await getCategories();
          if (categoriesResponse && categoriesResponse.length > 0) {
            setCategories(categoriesResponse);

            const updatedRecommendedJobs = limitedRecommendedJobs.map((job) => {
              const category = categoriesResponse.find(
                (category) => category._id === job.category
              );
              return {
                ...job,
                categoryName: category ? category.name : "Unknown Category",
              };
            });
            setRecommendedJobs(updatedRecommendedJobs);
          } else {
            console.log("No Categories Found");
          }
        } else {
          console.log("No Recommendations");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchRecommended();
  }, []);

  const redirect = ()=>{
    if(auth){
      navigate("/internships")
    } else {
      navigate("/internships")
    }
  }
  const getRelativeTime = (createdAt) => {
    const currentDate = moment();
    const jobDate = moment(createdAt);
    const diffInDays = currentDate.diff(jobDate, "days");

    if (diffInDays === 0) {
      return "Today";
    } else if (diffInDays === 1) {
      return "Yesterday";
    } else if (diffInDays < 7) {
      return `${diffInDays} days ago`;
    } else if (diffInDays < 30) {
      const diffInWeeks = Math.floor(diffInDays / 7);
      return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    } else {
      const diffInMonths = Math.floor(diffInDays / 30);
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    }
  };
  return (
    <>
      {/* <CommonBar /> */}
      {/* <div className="w-full my-6 h-px bg-gray-300"></div> */}

      <div className="w-full flex-row">
        <div className=" w-full h-auto p-4 flex-col justify-center items-center gap-8 inline-flex">
          <div className="w-full h-auto justify-center items-center inline-flex ">
            <div className="w-[500px] h-auto  flex justify-start items-start  object-contain">
              <img
                className=" w-full"
                src="./combine_application.png"
                alt="Error background image"
              />
            </div>
          </div>
          <div className="self-stretch h-[120px] flex-col justify-center items-center gap-4 flex">
            <div className="text-center text-teal-950 text-xl font-black font-satoshi capitalize leading-normal">
              Application Sent!
            </div>
            <div className="self-stretch text-center text-slate-500 text-sm font-medium font-satoshi leading-tight">
              Track all your applications in one place and get notified when you
              get selected.
            </div>
            <div className="flex justify-center items-center">
              <BorderButton
                textColor={"gray"}
                onClick={() => 
                  {
                    Mixpanel.track("track_application_clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type:"webapp",
                    });
                  navigate("/applications")
                  }
                }
                text={"Track Application"}
                className={"hover:shadow-sm"}
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.8469 6.98009L10.3469 1.98009C10.2442 1.91157 10.1235 1.875 10 1.875C9.87653 1.875 9.75583 1.91157 9.65312 1.98009L2.15313 6.98009C2.06751 7.03722 1.99732 7.1146 1.9488 7.20537C1.90029 7.29614 1.87493 7.39748 1.875 7.50041V15.6254C1.875 15.9569 2.0067 16.2749 2.24112 16.5093C2.47554 16.7437 2.79348 16.8754 3.125 16.8754H16.875C17.2065 16.8754 17.5245 16.7437 17.7589 16.5093C17.9933 16.2749 18.125 15.9569 18.125 15.6254V7.50041C18.1251 7.39748 18.0997 7.29614 18.0512 7.20537C18.0027 7.1146 17.9325 7.03722 17.8469 6.98009ZM7.55625 11.8754L3.125 15.0004V8.71369L7.55625 11.8754ZM8.83516 12.5004H11.1648L15.5891 15.6254H4.41094L8.83516 12.5004ZM12.4438 11.8754L16.875 8.71369V15.0004L12.4438 11.8754Z"
                      fill="#667984"
                    />
                  </svg>
                }
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center  md:hidden ">
            {}
            <h2
              className="text-green-500 font-bold"
              onClick={redirect}
            >
              {" "}
              Explore More Opportunities
            </h2>
            <svg
              onClick={redirect}
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M17.1927 7.94219L10.9427 14.1922C10.8846 14.2503 10.8157 14.2964 10.7398 14.3279C10.664 14.3593 10.5826 14.3755 10.5005 14.3755C10.4184 14.3755 10.337 14.3593 10.2612 14.3279C10.1853 14.2964 10.1164 14.2503 10.0583 14.1922L3.8083 7.94219C3.7208 7.85478 3.66119 7.74337 3.63704 7.62207C3.61288 7.50076 3.62526 7.37502 3.6726 7.26076C3.71995 7.14649 3.80013 7.04884 3.903 6.98017C4.00587 6.91151 4.12681 6.8749 4.25049 6.875H16.7505C16.8742 6.8749 16.9951 6.91151 17.098 6.98017C17.2009 7.04884 17.281 7.14649 17.3284 7.26076C17.3757 7.37502 17.3881 7.50076 17.3639 7.62207C17.3398 7.74337 17.2802 7.85478 17.1927 7.94219Z"
                fill="#16C083"
              />
            </svg>
          </div>
          <div className={`w-full hidden md:block`}>
            {recommendedJobs &&
              recommendedJobs.map((ele) => {
                return (
                  <div
                    className="self-stretch py-2 h-auto max-w-full flex-col justify-start items-start gap-4 flex"
                    key={ele.id}
                  >
                    <div className="self-stretch p-4 md:p-6 bg-white rounded-xl border border-neutral-300 justify-between items-center inline-flex flex-col md:flex-row">
                      <div className="flex justify-start items-start gap-2 md:gap-6">
                        <div className="w-12 h-12 relative">
                          <div className="w-12 h-12 left-0 top-0 absolute bg-stone-300 rounded-full" />
                          <img
                            className="w-12 h-12 left-0 top-0 absolute object-cover"
                            src={ele?.company_info?.logo}
                            alt="placeholder"
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start gap-1">
                          <div className="text-teal-950 text-lg md:text-xl font-bold leading-normal">
                            {ele?.job_profile}
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="text-slate-500 text-base font-normal leading-relaxed">
                              {ele?.company_info?.name}
                            </div>
                            <div className="w-1 h-1 bg-slate-500 rounded-full" />
                            <div className="text-slate-500 text-base font-normal leading-relaxed">
                              {ele?.location}
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <button className="text-green-550 border-2 px-2 py-1 border-green-600 rounded-full text-sm hover:border-green-700">
                              {ele?.job_type}
                            </button>
                            <div className="w-px bg-neutral-300 h-5" />
                            <button className="text-green-550 border-2 px-2 py-1 border-green-600 rounded-full text-sm hover:border-green-700">
                              {ele?.categoryName}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-start items-center mt-4 md:mt-1 gap-4 md:gap-6">
                        <div className="text-center text-teal-950 text-sm md:text-base font-medium leading-snug">
                          {ele.created_at
                            ? `Posted ${getRelativeTime(ele.created_at)}`
                            : ""}
                        </div>
                        <div className="flex justify-center items-center">
                          <FillButton
                            onClick={() =>{ 
                              navigate(`/job?id=${ele._id}`)
                            }}
                            text={"View details"}
                            textColor={"white"}
                            color={"green"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
